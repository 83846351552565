<!-- 申请离职 -->
<template>
    <div class="apply_dimission">
        <!-- <van-nav-bar title="服务评价"  left-text="返回" left-arrow @click-left="onClickLeft" /> -->

        <div class="service">
            <div class="username">
                <div class="left font_space">
                    驻厂服务：
                </div>
                <div class="right">
                    <van-rate v-model="value1" color="#4C94F6" />
                </div>
            </div>
            <div class="username">
                <div class="left">
                    业务员服务：
                </div>
                <div class="right">
                    <van-rate v-model="value2" color="#4C94F6" />
                </div>
            </div>
            <div class="username ">
                <div class="left font_space">
                    工厂评级：
                </div>
                <div class="right">
                    <van-rate v-model="value3" color="#4C94F6" />
                </div>
            </div>
            <div style="padding-top:0.2rem">
                <div class="left font_space">
                    评价建议：
                </div>
                <div class="note">
                    <van-field v-model="note" rows="3" autosize :border="true" type="textarea" maxlength="100" placeholder="评价建议" show-word-limit />
                </div>
            </div>
        </div>
        <div class="checkBox">
            <div class="title"></div>
            <van-checkbox v-model="checked" class="checkbox">匿名提交</van-checkbox>
        </div>
        <!-- 底部 -->
                <div class="footer-bottom"></div>
                <div class="footer">
                    <div @click="submit"  :class="is_click ? 'd8' : ''">提交</div>
                </div>
        <!-- <van-button type="info" size="large" class="btn" @click="submit" :style="is_click ? 'background:#aaa' : ''">提交</van-button> -->

    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// import { dead_apply } from '@/api/index'
import '@/assets/css/index.css'
// import { Notify } from 'vant';
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            checked: true,
            value1: 0,
            value2: 0,
            value3: 0,
            note: "",
            list: {},
            is_click: false
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        onClickLeft() {
            this.$router.replace({ path: '/dimission' })
        },
        submit() {
            if(this.is_click) return
            this.is_click = true
            this.Request('client/dead/apply', 'post', {
                factory_id: this.list.basic.incumbent_factory_id,
                factory_member_id: this.list.basic.employ_member_id,
                team_id: localStorage.getItem('team_id'),
                dead_time: Math.round(new Date(this.list.basic.leaveDate).getTime() / 1000).toString(),
                dead_reason: this.list.basic.note || '',
                factory_member_score: this.value1, // 工厂评分
                bus_member_score: this.value2, // 业务员评分
                factory_score: this.value3, // 驻厂评分
                comment: this.node || '',
                is_anonymous: this.checked ? 1 : 0
            }).then(res => {
                this.is_click = false
                if (res.status == 0) {
                    this.$store.state.applyList = {}
                    this.$router.replace({ path: '/dimissionSuccess' })
                }
            })
        },
    },

    //生命周期 - 创建完成（可以访问当前this实例）
    created() { },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.list = this.$store.state.applyList

    },
};
</script>
<style scoped>
/* @import url(); 引入公共css类 */
.apply_dimission {
    background: white;
    position: relative;
}
.checkBox {
    display: flex;
    flex: 1;
    justify-content: space-between;
    width: 700px;
    margin: 25px auto;
    color: #333333;
    padding-bottom: 30px;
}
.van-nav-bar {
    position: relative;
}
.checkbox {
    font-size: 24px;
}
.checkbox >>> .van-icon {
    font-size: 25px;
}
.service {
    /* border: 1px solid; */
    width: 700px;
    margin: 0 auto;
    border-radius: 10px;
    font-size: 28px;
    /* padding: 20px 0; */
    box-sizing: border-box;
}
.title {
    font-size: 30px;
    font-weight: bold;
}
.left {
    width: 182px;
    white-space: nowrap;
    text-align: left;
}
.placeholder {
    margin-left: 50px;
}
.right {
    color: gray;
}
.username {
    text-align: left;
    border-bottom: 1px solid #f8f8f8;
    display: flex;
    padding: 0.2rem 0;
    align-items: center;
}
.note {
    border: 2px solid #f8f8f8;
    border-radius: 10px;
    z-index: 100;
    margin-top: 0.2rem;
    overflow: hidden;
    /* height: 210px; */
}
.btn {
    position: fixed;
    left: 0;
    bottom: 0;
}
.font_space {
    letter-spacing: 7px;
}
</style>
